<template>
  <div class="card">
    <slot />
    <div class="actions" v-if="$slots.actions">
      <slot name="actions" />
    </div>
  </div>
</template>
<style scoped>
.card {
  border-radius: 5px;
  background-color: white;
  padding: var(--grid);
  margin-bottom: var(--half-grid);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);

  display: flex;
  flex-direction: column;
  gap: var(--grid);
}

.actions {
  display: flex;
  justify-content: flex-end;
}
</style>
